import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Link from 'components/shared/Link';

import Typography500 from 'common/components/Typography500';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  ITRoot: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      boxShadow: '0px 0px 16px -4px rgba(0, 0, 0, 0.20)',
      minHeight: theme.spacing(22.25),
    },
    [theme.breakpoints.down('md')]: {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  ITBoxWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  ITIconTitle: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flex: 1,
    },
  },
  ITTitle: {
    color: theme.palette.grey[500],
  },
  ITSubTitle: {
    color: theme.palette.grey[400],
    letterSpacing: 0.25,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(1),
    },
  },
  ITIcon: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.4),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(1),
      '& .MuiSvgIcon-root': {
        fontSize: theme.spacing(5.5),
        fill: theme.palette.secondary.main,
      },
    },
  },
  ITArrowIcon: {
    color: theme.palette.grey[400],
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const InformationTile = (props) => {
  const {
    informationTileTitle,
    informationTileSubTitle,
    informationTileIcon,
    informationTilePath,
  } = props;
  const classes = useStyles();

  return (
    <Link href={informationTilePath}>
      <Box className={classes.ITRoot} href={informationTilePath}>
        <Box className={classes.ITBoxWrapper}>
          <Box className={classes.ITIconTitle}>
            <Box className={classes.ITIcon}>{informationTileIcon}</Box>
            <Typography500 variant="h4" component="h4" className={classes.ITTitle}>
              {informationTileTitle}
            </Typography500>
          </Box>

          <ChevronRightIcon className={classes.ITArrowIcon} />
        </Box>
        {informationTileSubTitle && (
          <Typography variant="body2" component="P" className={classes.ITSubTitle}>
            {informationTileSubTitle}
          </Typography>
        )}
      </Box>
    </Link>
  );
};

export default InformationTile;
